import _defineProperty from "define-property";
var exports = {};
var define = _defineProperty;
/**
 * Store position for a node
 */

exports = function Position(start, parser) {
  this.start = start;
  this.end = {
    line: parser.line,
    column: parser.column
  };
  define(this, "content", parser.orig);
  define(this, "source", parser.options.source);
};

export default exports;