import _extendShallow from "extend-shallow";
import _sourceMap from "source-map";
import _sourceMapResolve from "source-map-resolve";
var exports = {};

/**
 * Module dependencies
 */
exports.extend = _extendShallow;
exports.SourceMap = _sourceMap;
exports.sourceMapResolve = _sourceMapResolve;
/**
 * Convert backslash in the given string to forward slashes
 */

exports.unixify = function (fp) {
  return fp.split(/\\+/).join("/");
};
/**
 * Return true if `val` is a non-empty string
 *
 * @param {String} `str`
 * @return {Boolean}
 */


exports.isString = function (str) {
  return str && typeof str === "string";
};
/**
 * Cast `val` to an array
 * @return {Array}
 */


exports.arrayify = function (val) {
  if (typeof val === "string") return [val];
  return val ? Array.isArray(val) ? val : [val] : [];
};
/**
 * Get the last `n` element from the given `array`
 * @param {Array} `array`
 * @return {*}
 */


exports.last = function (arr, n) {
  return arr[arr.length - (n || 1)];
};

export default exports;